






















import { Component, Prop, PropSync, Watch } from 'vue-property-decorator'
import VueBase from '@/VueBase'

@Component({ name: 'VerifyCode' })
export default class VerifyCode extends VueBase {
  @Prop({ default: 6 }) nums?: Number
  @PropSync("code") codeProp!: string
  
  @Watch('vCode', {deep: true})
  dataChange(val: any) {
    this.codeProp = val.join('')
  }
  @Watch('code', {deep: true})
  codeChange(val: any) {
    this.vCode = this.codeProp.split('')
  }

  private vCode: any = []
  handleInput(e: any, i: any) {
    let ele = e.target;
    let siblingsNode = ele.parentNode.children;
    if (!e.data){
      this.$delete(this.vCode, i-1);
      if(i <= 2){
        i = 2;
      }
      siblingsNode[i-2].focus();
      return
    }
    this.$set(this.vCode,i-1,e.data)
    if (i >= siblingsNode.length-1){
      i = siblingsNode.length-1;
    }
    if (e.data){
      siblingsNode[i].focus();
    }
  }

}
